import React, { useContext, useState } from "react";
import { graphql, navigate } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { useIntl } from "react-intl";
import { Box, Image, Stack, Text } from "grommet";
import { Heading } from "../components/typo";
import styled from "styled-components";

const RegistrationContainer = styled.div`
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
`;

const Welcome = ({ pageContext }) => {
  const intl = useIntl();
  const locale = pageContext.locale;
  const originalPath = pageContext.originalPath;

  return (
    <Layout locale={locale} originalPath={originalPath}>
      <Seo title={intl.formatMessage({ id: "registration-heading" })} />
      <RegistrationContainer>
        <Heading level="1" textAlign="center">
          {intl.formatMessage({ id: "welcomeTitle" })}
        </Heading>
        <Text margin={{ bottom: "50px" }} as="p" textAlign="center">
          {intl.formatMessage({ id: "welcomeText" })}
        </Text>
      </RegistrationContainer>
    </Layout>
  );
};

export default Welcome;
